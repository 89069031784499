<template>
  <div class="page">
    <div class="wrapper">
      <Backlink title="service" />
      <div class="content">
        <div class="content__page">
          <div class="service__container">
            <router-link
              :to="{
                name: 'Credit registration',
                params: { backlink: '/credit' },
              }"
              class="service"
              tag="button"
            >
              <div class="service__title">{{ "new_credit" | localize }}</div>
            </router-link>
            <router-link
              :to="{
                name: 'Refinance credit registration',
                params: { backlink: '/credit' },
              }"
              class="service service_second"
              tag="button"
            >
              <div class="service__title">
                {{ "refinance_credit" | localize }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'

export default {
  name: 'Credit',
  components: { Backlink }
}
</script>
